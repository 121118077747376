"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { downloadRepo } from "@/actions/github";
import { trackEvent } from "@/actions/meta";
import { getStripe } from "@/actions/stripe/client";
import {
  checkoutWithStripe,
  getStripePriceAmount,
} from "@/actions/stripe/server";
import { checkProductAccess } from "@/actions/user";
import { Check, ChevronRight, Download } from "lucide-react";
import { toast } from "sonner";
import { useServerAction } from "zsa-react";

import { formatPrice } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import { FacebookTrackOnClick } from "@/components/meta/facebook-track-onclick";

export const CheckoutDownloadButton = ({
  priceId,
  coupon,
  className,
  mode,
  showPrice = false,
  children,
  free = false,
  repo,
  owner,
}: {
  priceId?: string;
  coupon?: string;
  mode?: "payment" | "setup" | "subscription";
  className?: string;
  showPrice?: boolean;
  children?: React.ReactNode;
  free?: boolean;
  repo?: string;
  owner?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [hasAccess, setHasAccess] = useState(free);
  const [priceInfo, setPriceInfo] = useState<{
    amount?: number;
    currency?: string;
  }>({});

  const { execute: getPrice } = useServerAction(getStripePriceAmount);
  const { execute: checkAccess } = useServerAction(checkProductAccess);
  const { execute: executeDownloadRepo, isPending: isDownloading } =
    useServerAction(downloadRepo);

  useEffect(() => {
    if (free) {
      setHasAccess(true);
      setLoading(false);
      return;
    }

    const fetchAccess = async () => {
      if (!priceId) return;
      const [data, err] = await checkAccess({ priceId });

      if (err) {
        toast.error(err.message);
        setHasAccess(false);
      } else {
        setHasAccess(data);
      }
      setLoading(false);
    };
    fetchAccess();
  }, [priceId, checkAccess]);

  useEffect(() => {
    if (!priceId) return;
    const loadPriceInfo = async () => {
      const [data, err] = await getPrice({ priceId });
      if (err) {
        toast.error(err.message);
        return;
      }
      if (!data.amount || !data.currency) {
        toast.error("Error fetching price information");
        return;
      }
      setPriceInfo({ amount: data.amount, currency: data.currency });
    };
    loadPriceInfo();
  }, [priceId, showPrice]);

  const handleClick = async () => {
    if (!priceId) return;
    setLoading(true);
    const [data, err] = await checkoutWithStripe({ priceId, coupon, mode });
    if (err) {
      toast.error(err.message);
      setLoading(false);
      return;
    }
    if (!data?.sessionId) {
      toast.error("Error creating checkout session " + data?.errorRedirect);
      setLoading(false);
      return;
    }

    const stripe = await getStripe();
    stripe?.redirectToCheckout({ sessionId: data?.sessionId });
    setLoading(false);
  };

  const handleDownload = async () => {
    if (!repo || !owner) {
      toast.error("Error occurred while downloading. Please try again.");
      return;
    }

    const [data, error] = await executeDownloadRepo({ owner, repo });

    if (error) {
      toast.error(
        error.message || "Error occurred while downloading. Please try again.",
      );
      console.error("error", error);
      return;
    }

    if (data.success && data.downloadUrl) {
      window.location.href = data.downloadUrl;
    } else {
      toast.error(
        data.error || "Failed to get download URL. Please try again.",
      );
    }
  };

  const isDisabled = showPrice
    ? loading || !priceInfo?.amount || !priceInfo?.currency
    : loading;

  if (hasAccess && repo && owner) {
    return (
      <Button
        onClick={handleDownload}
        disabled={isDownloading}
        className={className}
      >
        {isDownloading ? (
          <>
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            Downloading...
          </>
        ) : (
          <>
            Download
            <Download className="h-4 w-4" />
          </>
        )}
      </Button>
    );
  }

  if (hasAccess) {
    return (
      <Link href="/templates" className={className}>
        You have access
        <Check className="h-4 w-4" />
      </Link>
    );
  }

  return (
    <FacebookTrackOnClick
      event={{
        event_name: "InitiateCheckout",
        custom_data: {
          value: priceInfo.amount,
          currency: priceInfo.currency,
        },
      }}
      action={trackEvent}
    >
      <Button onClick={handleClick} disabled={isDisabled} className={className}>
        {isDisabled ? (
          <>
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            Loading...
          </>
        ) : (
          <>
            {children}
            {showPrice && priceInfo.amount && priceInfo.currency && (
              <>
                {" - "}
                {formatPrice(priceInfo.amount, {
                  currency: priceInfo.currency,
                })}
              </>
            )}
            <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
          </>
        )}
      </Button>
    </FacebookTrackOnClick>
  );
};
