"use client";

import Link from "next/link";
import { Doc } from "@/.contentlayer/generated";
import { motion } from "framer-motion";
import { ChevronRight } from "lucide-react";

import { licenses } from "@/config/pricing";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { CheckoutButton } from "@/components/checkout-button";
import { AuroraText } from "@/components/landing/aurora-text";
import LineShadowText from "@/components/landing/line-shadow-text";
import TechStack from "@/components/tech-stack";

export default function Hero({ post }: { post: Doc }) {
  return (
    <section id="hero">
      <div className="relative h-full overflow-hidden md:py-14 max-w-6xl mx-auto">
        <div className="container z-10 flex flex-col">
          <div className="mt-10 md:mt-20 grid grid-cols-1">
            <div className="flex flex-col items-start md:items-center gap-6 mb-6 text-left md:text-center">
              <Link
                href={post.slug}
                className={cn(
                  buttonVariants({
                    variant: "outline",
                    size: "sm",
                  }),
                  "rounded-full",
                )}
              >
                🎉 <Separator className="mx-2 h-4" orientation="vertical" />
                Introducing {post.title}
                <ChevronRight className="ml-1 h-4 w-4 text-muted-foreground" />
              </Link>

              <motion.h1 className="text-balance text-5xl font-semibold leading-none tracking-tighter sm:text-6xl md:text-7xl lg:text-8xl">
                Ship <AuroraText>beautiful</AuroraText> products in{" "}
                <LineShadowText
                  text="half"
                  className="italic tracking-tighter font-bold"
                />{" "}
                the time
              </motion.h1>

              <motion.p className="max-w-[64rem] text-balance text-sm tracking-tight text-muted-foreground md:text-xl">
                50+ beautiful sections and templates built with{" "}
                <strong>React</strong>, <strong>Typescript</strong>,{" "}
                <strong>Tailwind CSS</strong>, and{" "}
                <strong>Framer Motion</strong>.
                <br />
                Save thousands of hours, create a beautiful landing page, and
                convert your visitors into customers.
              </motion.p>
              <div className="flex flex-col gap-4 justify-start md:justify-center w-full sm:w-auto sm:flex-row">
                <CheckoutButton
                  priceId={licenses.individual.priceId}
                  coupon={licenses.individual.coupon}
                  className={cn(
                    buttonVariants({ variant: "rainbow", size: "lg" }),
                    "w-full gap-2 whitespace-nowrap",
                  )}
                >
                  Get Unlimited Access
                </CheckoutButton>
                <Link
                  href="/templates"
                  className={cn(
                    buttonVariants({
                      variant: "outline",
                      size: "lg",
                    }),
                    "w-full gap-2 whitespace-nowrap",
                  )}
                >
                  Browse Docs
                  <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
                </Link>
              </div>
            </div>
          </div>

          <motion.div className="relative mx-auto flex w-full max-w-[1000px] items-center justify-center">
            <TechStack
              className="mx-auto text-foreground"
              technologies={[
                "nextjs",
                "react",
                "typescript",
                "tailwindcss",
                "framermotion",
                "shadcn",
              ]}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
